/* External dependencies */
import { Anchor, Col, Div, Row, Text } from 'atomize';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useState } from 'react';
import * as ReactRedux from 'react-redux';

/* Local dependencies */
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';
import { formStyle, wrapStyle } from '../../form/FormStyles';
import PrimaryButton from '../../buttons/PrimaryButton';
import { rememberedPassword, requestVerificationCode } from '../redux/actions';
import FormField from '../../form/FormField';

export default function ForgotPassword() {
  const [username, setUsername] = useState(null);
  const { error, loading } = ReactRedux.useSelector(
    (store: RootState) => store.login,
  );

  const validateUsername = (username) => {
    setUsername(username.trim());
  };

  const dispatch = ReactRedux.useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(requestVerificationCode(username));
  };

  const onRememberedPassword = () => dispatch(rememberedPassword(username));

  const isInvalid = !username;

  const labelPhone = formatMessage('phone');
  const phonePlaceholder = formatMessage('phonePlaceholder');
  const sentSMSCode = formatMessage('sentSMSCode');

  return (
    <Div {...wrapStyle}>
      <form onSubmit={onSubmit} style={formStyle}>
        <Row m="0">
          <Col
            border={{ b: '1px solid' }}
            textAlign="left"
            borderColor="basic_border_color"
          >
            <Text tag="h1" textSize="18px" textWeight="bold" m="1rem">
              {<FormattedMessage id="loginSignInWithSMS" />}
            </Text>
          </Col>
        </Row>
        <Row p="1rem" m="0">
          <FormField
            disabled={loading}
            fieldDescription={sentSMSCode}
            label={labelPhone}
            inputValue={username}
            inputType="tel"
            inputName="username"
            onChange={(e) => validateUsername(e.target.value)}
            placeholder={phonePlaceholder}
            required={true}
          />
          {error && (
            <Col>
              <Text textColor="red">
                {<FormattedMessage id={error.message} />}
              </Text>
            </Col>
          )}
        </Row>
        <Row
          m="0"
          border={{ t: '1px solid' }}
          borderColor="basic_border_color"
          p="1rem"
        >
          <Col d="flex" justify="flex-start" align="center">
            <Anchor onClick={onRememberedPassword}>
              <Text textSize="caption">
                {<FormattedMessage id="rememberedPassword" />}
              </Text>
            </Anchor>
          </Col>
          <Col d="flex" justify="flex-end">
            <PrimaryButton disabled={isInvalid || loading} loading={loading} onClick={onSubmit}>
              {<FormattedMessage id="getSMSCode" />}
            </PrimaryButton>
          </Col>
        </Row>
      </form>
    </Div>
  );
}
